import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./index.css";
import App from "./components/App";
import { default as FourCharOldQuery } from "./4char/OldQuery";
import { default as FourCharNewQuery } from "./4char/NewQuery";
import { default as ThreeCharNewQuery } from "./3char/NewQuery";
import { default as FiveCharNewQuery} from "./5char/NewQuery";
import { default as DatelessOldQuery} from "./dateless/OldQuery";
import { default as DatelessNewQuery} from "./dateless/NewQuery";

const Root = () => {
  return (
    <div className="app-container">
      <div>
        <nav className="app-navigation">
            <ul>
                <li className="dropdown">
                    <span>Current</span>
                    <div className="dropdown-content">
                        <Link to="3char">3 Chars</Link>
                        <Link to="/">4 Chars</Link>
                        <Link to="/5char">+5 Chars</Link>
                    </div>
                </li>
                <li>
                    <Link to="/dateless">Dateless</Link>
                </li>
            </ul>
        </nav>
          <h1 className="app-title">Elasticsearch Viewer</h1>
      </div>
      <Routes>
        <Route
          path="/"
          element={
            <App
              onOldQuery={FourCharOldQuery}
              onNewQuery={FourCharNewQuery}
              endpointName="endpoint"
              headerText="4 Chars Queries"
            />
          }
        />
        <Route
          path="/3char"
          element={
            <App
              onOldQuery={FourCharOldQuery}
              onNewQuery={ThreeCharNewQuery}
              endpointName="3char_endpoint"
              headerText="1-2-3 Chars Queries"
            />
          }
        />
          <Route
          path="/5char"
          element={
            <App
              onOldQuery={FourCharOldQuery}
              onNewQuery={FiveCharNewQuery}
              endpointName="5char_endpoint"
              headerText="+5 Chars Queries"
            />
          }
        />
          <Route
              path="/dateless"
              element={
                  <App
                      onOldQuery={DatelessOldQuery}
                      onNewQuery={DatelessNewQuery}
                      endpointName="dateless"
                      headerText="Dateless"
                  />
              }
          />
      </Routes>
      
    </div>
  );
};

export default Root;
